<template>
  <el-drawer
    v-model="isShowDetailDrawer"
    :show-close="false"
    modal-class="elv-report-trade-overlay"
    class="elv-report-trade-drawer"
    :before-close="onCheckDrawerStatus"
    :size="400"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-report-trade-drawer-header__title">
        {{ t('common.trade') }}
      </h4>
      <SvgIcon
        name="close"
        width="24"
        height="24"
        class="elv-report-trade-drawer-header__close"
        @click="onCheckDrawerStatus"
      />
    </template>
    <el-scrollbar v-loading="loading">
      <div class="elv-report-trade-drawer-info-detail-content">
        <div class="elv-report-trade-drawer-info__title">
          <p>
            {{ t(`${currentTradeShowInfo?.label}`) }}
            <span v-if="isOptionOrFuture && tradeAssetInfo.name" class="base-assets"
              ><span class="line"></span>{{ tradeAssetInfo.name }}</span
            >
          </p>
          <h3
            class="elv-report-trade-drawer-info__total"
            :title="`${detailTradeData?.baseAmount}${tradeAssetInfo.symbol}`"
          >
            {{ formatNumber(detailTradeData?.baseAmount, 2) }}
            <span v-if="!isOptionOrFuture">{{ tradeAssetInfo.symbol }}</span>
          </h3>
        </div>
        <ul>
          <li class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('report.Account') }} </span>
            <div class="elv-report-trade-drawer-info__from-content">
              <IconWithTitleCell :icon="accountInfo.icon" :title="accountInfo.title" />
            </div>
          </li>
          <li v-if="isOptionOrFuture" class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('report.positionAndSide') }} </span>
            <div class="elv-report-trade-drawer-info__from-content normal">
              <IconWithTitleCell
                :title-weight="400"
                :svg-name="positionSideInfo.svgName || ''"
                :title="t(positionSideInfo.label) || '-'"
              />
            </div>
          </li>
          <li class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('report.Base Asset') }} </span>
            <div class="elv-report-trade-drawer-info__from-content normal">
              <IconWithTitleCell :icon="tradeAssetInfo.platformIcon" :title="tradeAssetInfo.title" />
            </div>
          </li>
          <li class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('report.baseAndAmount') }} </span>
            <div class="elv-report-trade-drawer-info__from-content normal">
              <VerticalTextCell :up-text="upBaseAmount" :down-text="downBaseAmountFC" />
            </div>
          </li>
          <li class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('report.counterAsset') }} </span>
            <div class="elv-report-trade-drawer-info__from-content normal">
              <IconWithTitleCell
                :icon="detailTradeData.counterUnderlyingCurrency?.logo"
                :title="detailTradeData.counterUnderlyingCurrency?.showSymbol || ''"
              />
            </div>
          </li>
          <li class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('report.counterAndAmount') }} </span>
            <div class="elv-report-trade-drawer-info__from-content normal">
              <VerticalTextCell :up-text="upCounterAmount" :down-text="downCounterAmountFC" />
            </div>
          </li>
          <li class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('report.feeAsset') }} </span>
            <div class="elv-report-trade-drawer-info__from-content normal">
              <IconWithTitleCell
                :icon="detailTradeData.feeUnderlyingCurrency?.logo || ''"
                :title="detailTradeData.feeUnderlyingCurrency?.showSymbol || '-'"
              />
            </div>
          </li>
          <li class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('report.feeAndAmount') }} </span>
            <div class="elv-report-trade-drawer-info__from-content normal">
              <VerticalTextCell :up-text="upFeeAmountText" :down-text="downFeeAmountText" />
            </div>
          </li>
          <li class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('report.source') }} </span>
            <div class="elv-report-trade-drawer-info__from-content normal">
              <span>{{ detailTradeData.source?.name || '' }}</span>
            </div>
          </li>
          <li class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('report.tradeId') }} </span>
            <div class="elv-report-trade-drawer-info__from-content normal">
              <IconWithTitleCell
                :icon="tradeAssetInfo.icon"
                :title="formatInputAddress(detailTradeData?.tradeHash) || '-'"
              />
            </div>
          </li>
          <li class="elv-report-trade-drawer-info__from">
            <span class="elv-report-trade-drawer-info__from-title">{{ t('common.dateTime') }} </span>
            <div class="elv-report-trade-drawer-info__from-content normal">
              <DateTimeCell :params="{ detailTradeData }" bottom-fields="tradeHash" />
            </div>
          </li>
        </ul>

        <div v-if="detailTradeData?.journalActivityList?.length" class="elv-report-trade-drawer-journal">
          <p class="elv-report-trade-drawer-journal-title">{{ t('report.relatedJournal') }}</p>
          <div class="elv-report-trade-drawer-journal-list">
            <div v-for="(journalActivity, index) in detailTradeData?.journalActivityList" :key="index">
              <div class="elv-report-trade-drawer-journal-item__type">
                <p>{{ journalActivity?.journalType?.name }}</p>
                <span>{{ journalActivity?.journalNo }}</span>
              </div>
              <ol>
                <li>
                  <span class="elv-report-trade-drawer-journal-item-entry__direction title" />
                  <p
                    class="elv-report-trande-drawer-journal-item-entry__account title"
                    :class="{ 'has-match': entryHasReconciliationMatch(index) }"
                  >
                    {{ t('common.account') }}
                  </p>
                  <div class="elv-report-trade-drawer-journal-item-entry__debit title">
                    {{ t('common.debit') }}
                  </div>
                  <div class="elv-report-trade-drawer-journal-item-entry__credit title">
                    {{ t('common.credit') }}
                  </div>
                  <p
                    v-if="entryHasReconciliationMatch(index)"
                    class="elv-report-trade-drawer-journal-item-entry__match title"
                  ></p>
                </li>
                <li
                  v-for="item in journalActivity?.entryList"
                  :key="item?.journalEntryId"
                  :class="{ 'is-matched': item?.matched }"
                >
                  <span class="elv-report-trade-drawer-journal-item-entry__direction">{{ item?.balanceType }}</span>
                  <div
                    class="elv-report-trande-drawer-journal-item-entry__account"
                    :class="{ 'has-match': entryHasReconciliationMatch(index) }"
                  >
                    {{ item?.chartOfAccount?.name }}
                    <div
                      v-if="item?.auxiliaryValueList?.length"
                      class="elv-report-trade-drawer-journal-item-entry__auxiliary-list"
                    >
                      <template v-for="auxiliaryValue in item.auxiliaryValueList" :key="auxiliaryValue.auxiliaryTypeId">
                        <div
                          v-if="getAuxiliaryValue(auxiliaryValue)"
                          class="elv-report-trade-drawer-journal-item-entry__account-auxiliary"
                        >
                          {{ getAuxiliaryValue(auxiliaryValue) }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <p class="elv-report-trade-drawer-journal-item-entry__debit">
                    <template v-if="item?.balanceType === 'Dr'">
                      <p
                        :title="`${fieldValueFormat(
                          item?.amountFC,
                          {
                            price: true,
                            keepPoint: true,
                            symbol: `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          },
                          'NUMBER'
                        )}`"
                      >
                        {{
                          formatNumberToSignificantDigits(
                            item?.amountFC,
                            2,
                            `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          )
                        }}
                      </p>
                      <span
                        :title="`${formatNumber(item?.amount, 9)} ${
                          item?.underlyingCurrency?.showSymbol ?? item?.currency
                        }`"
                        >{{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                        {{ item?.underlyingCurrency?.showSymbol ?? item?.currency }}</span
                      >
                    </template>
                    <template v-else>-</template>
                  </p>
                  <p class="elv-report-trade-drawer-journal-item-entry__credit">
                    <template v-if="item?.balanceType === 'Cr'">
                      <p
                        :title="`${fieldValueFormat(
                          item?.amountFC,
                          {
                            price: true,
                            keepPoint: true,
                            symbol: `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          },
                          'NUMBER'
                        )}`"
                      >
                        {{
                          formatNumberToSignificantDigits(
                            item?.amountFC,
                            2,
                            `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          )
                        }}
                      </p>
                      <span
                        :title="`${formatNumber(item?.amount, 9)} ${
                          item?.underlyingCurrency?.showSymbol ?? item?.currency
                        }`"
                        >{{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                        {{ item?.underlyingCurrency?.showSymbol ?? item?.currency }}</span
                      >
                    </template>
                    <template v-else>-</template>
                  </p>
                  <p
                    v-if="entryHasReconciliationMatch(index)"
                    class="elv-report-trade-drawer-journal-item-entry__match"
                  >
                    <el-tooltip v-if="item?.matched" effect="dark" placement="top" :show-after="500">
                      <SvgIcon name="Checkmark" width="16" height="16" fill="#41780C" />
                      <template #content>
                        <div>{{ t('report.matched') }}</div>
                      </template>
                    </el-tooltip>
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <Memo
          ref="uploadMemoRef"
          :memo-type="transactionFilterSaveType.TRADE"
          :current-data="detailTradeData ?? {}"
          @onMemoChange="onMemoChange"
          @onAttachmentsChange="onAttachmentsChange"
        />
      </div>
    </el-scrollbar>
  </el-drawer>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { isEmpty, map } from 'lodash-es'
import { ElMessage } from 'element-plus'
import Memo from '../../components/Memo.vue'
import TransactionsApi from '@/api/TransactionsApi'
import { positionOptions, tradeType } from '@/config'
import { useEntityStore } from '@/stores/modules/entity'
import { transactionFilterSaveType } from '@/config/transactions'
import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'
import VerticalTextCell from '@/components/Base/Table/VerticalTextCell.vue'
import IconWithTitleCell from '@/components/Base/Table/IconWithTitleCell.vue'
import { formatNumber, formatNumberToSignificantDigits, fieldValueFormat, formatInputAddress } from '@/lib/utils'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {}
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const loading = ref(false)
const detailTradeData = ref<any>({})
const isShowDetailDrawer = ref(false)

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const isOptionOrFuture = computed(() => {
  const showPlatformType = detailTradeData.value?.type?.split('_')[0]
  return ['OPTION', 'FUTURE'].includes(showPlatformType)
})

const entryHasReconciliationMatch = computed(() => {
  return (index: number) => {
    return detailTradeData.value?.journalActivityList?.[index]?.entryList?.some((entry: any) => entry?.matched)
  }
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const positionSideInfo = computed(() => {
  return (
    positionOptions.find((item) => item.value === detailTradeData.value?.positionSide) || { label: '', svgName: '' }
  )
})

const tradeAssetInfo = computed(() => {
  const assetData = { icon: '', title: '-', name: '', symbol: '', platformIcon: '' }
  if (isEmpty(detailTradeData.value.asset?.underlyingCurrency) && isEmpty(detailTradeData.value.asset?.derivative)) {
    return assetData
  }
  if (isEmpty(detailTradeData.value.asset?.underlyingCurrency)) {
    assetData.title = detailTradeData.value.asset?.derivative?.name || '-'
    assetData.icon = detailTradeData.value.platform?.logo || ''
    assetData.name = detailTradeData.value.asset?.derivative?.marginAssetCurrency?.name || ''
    assetData.symbol = detailTradeData.value.asset?.derivative?.marginAssetCurrency?.showSymbol || ''
    assetData.platformIcon = detailTradeData.value.entityAccount?.platform?.logo || ''
  } else {
    assetData.title = detailTradeData.value.asset?.underlyingCurrency?.showSymbol || '-'
    assetData.icon = detailTradeData.value.platform?.logo || ''
    assetData.name = detailTradeData.value.asset?.underlyingCurrency?.name || ''
    assetData.symbol = detailTradeData.value.asset?.underlyingCurrency?.symbol || ''
    assetData.platformIcon = detailTradeData.value.asset?.underlyingCurrency?.logo || ''
  }
  return assetData
})

const currentTradeShowInfo = computed(() => {
  return tradeType.find((item: any) => item.value === props.currentData.type)
})

const upBaseAmount = computed(() => {
  if (!detailTradeData.value?.baseAmount) return '-'
  const value = formatNumberToSignificantDigits(Number(detailTradeData.value?.baseAmount), 2, '', false)
  if (!isOptionOrFuture.value) {
    return `${value}\n${tradeAssetInfo.value.symbol}`
  }
  return value
})

const downBaseAmountFC = computed(() => {
  if (!detailTradeData.value?.baseAmountFC || isOptionOrFuture.value) return ''
  return formatNumberToSignificantDigits(
    detailTradeData.value?.baseAmountFC,
    2,
    `${entityDetail?.value.underlyingCurrency?.sign || `${entityDetail?.value.underlyingCurrency?.showSymbol} `}`
  )
})

const upCounterAmount = computed(() => {
  if (!detailTradeData.value?.counterAmount) return '-'
  const value = formatNumberToSignificantDigits(Number(detailTradeData.value?.counterAmount), 2, '', false)
  const symbol = detailTradeData.value?.counterCurrency
    ? detailTradeData.value.counterCurrency
    : detailTradeData.value.counterUnderlyingCurrency?.showSymbol
  return `${value}\n${symbol}`
})

const downCounterAmountFC = computed(() => {
  if (!detailTradeData.value?.counterAmountFC) return ''
  return formatNumberToSignificantDigits(
    detailTradeData.value?.counterAmountFC,
    2,
    `${entityDetail?.value.underlyingCurrency?.sign || `${entityDetail?.value.underlyingCurrency?.showSymbol} `}`
  )
})

const upFeeAmountText = computed(() => {
  if (!detailTradeData.value?.feeAmount) return '-'
  const value = formatNumberToSignificantDigits(Number(detailTradeData.value?.feeAmount), 2, '', false)
  const symbol = detailTradeData.value?.feeCurrency?.symbol
    ? detailTradeData.value?.feeCurrency?.symbol
    : (detailTradeData.value?.feeUnderlyingCurrency?.symbol ?? (detailTradeData.value?.feeCurrency || ''))
  return `${value}\n${symbol}`
})

const downFeeAmountText = computed(() => {
  if (!detailTradeData.value?.feeAmountFC) return ''
  return formatNumberToSignificantDigits(
    detailTradeData.value?.feeAmountFC,
    2,
    `${entityDetail?.value.underlyingCurrency?.sign || `${entityDetail?.value.underlyingCurrency?.showSymbol} `}`
  )
})

const accountInfo = computed(() => {
  const accountData = { icon: '', title: '' }
  if (isEmpty(detailTradeData.value.entityAccount)) return accountData
  accountData.title = detailTradeData.value?.entityAccount?.name || ''
  accountData.icon = detailTradeData.value?.entityAccount?.platform?.logo || ''
  return accountData
})

const getAuxiliaryValue = (item: any) => {
  if (item.auxiliaryType.type === 'ENTITY_ACCOUNT') {
    return item?.entityAccount?.name
  }

  if (item.auxiliaryType.type === 'COUNTERPARTY') {
    return item?.counterparty?.name
  }

  if (item.auxiliaryType.type === 'ITEM') {
    return item.auxiliaryItem?.value
  }

  return null
}

/**
 * 详情Memo数据变化触发
 * @param memo Memo数据
 */
const onMemoChange = async (memo: string) => {
  try {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.transaction?.update
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    loading.value = true
    const params = {
      memo
    }
    await TransactionsApi.editTradeDetail(entityId.value, detailTradeData.value.tradeId, params)
    ElMessage.success(t('message.editSuccess'))
  } catch (error: any) {
    ElMessage.error(error.message)
    loading.value = false
    console.error(error)
  } finally {
    loading.value = false
  }
}

/**
 * 详情Memo附件变化触发
 * @param fileList Memo附件列表
 */
const onAttachmentsChange = async (fileList: any[]) => {
  try {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.transaction?.update
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    loading.value = true
    const params = {
      tradeAttachmentIds: map(fileList, 'tradeAttachmentId') ?? []
    }
    await TransactionsApi.editTradeDetail(entityId.value, detailTradeData.value.tradeId, params)
    ElMessage.success(t('message.editSuccess'))
  } catch (error: any) {
    ElMessage.error(error.message)
    loading.value = false
    console.error(error)
  } finally {
    loading.value = false
  }
}

/**
 * 切换详情Drawer显隐状态
 */
const onCheckDrawerStatus = () => {
  isShowDetailDrawer.value = !isShowDetailDrawer.value
}

/**
 * 获取Trade详情数据
 */
const fetchTransactionDetail = async () => {
  loading.value = true
  try {
    const info = await TransactionsApi.getTradeDetail(entityId.value, props.currentData.tradeId)
    detailTradeData.value = info.data
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

watch(
  [() => isShowDetailDrawer.value, () => props.currentData],
  () => {
    if (isShowDetailDrawer.value && !isEmpty(props.currentData)) {
      fetchTransactionDetail()
    }
  },
  {
    immediate: true,
    flush: 'post'
  }
)

defineExpose({
  onCheckDrawerStatus
})
</script>
<style lang="scss" scoped>
.elv-report-trade-drawer {
  .elv-report-trade-drawer-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    margin: 0;
  }

  .elv-report-trade-drawer-header__close {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-report-trade-drawer {
  .el-drawer__header {
    padding: 26px 0px 18px;
    margin: 0 24px;
    border-bottom: 1px solid #edf0f3;
    height: 64px;
    box-sizing: border-box;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
  }

  .el-scrollbar__view {
    padding: 0 24px;
  }

  .elv-report-trade-drawer-info-detail-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    position: relative;

    .elv-report-transaction-drawer-memo {
      .elv-report-transaction-drawer-memo-upload {
        .el-upload-list__item {
          display: flex;
          flex-direction: row;
          padding-left: 0px;
        }
      }
    }
  }

  .elv-report-trade-drawer-info__title {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #838d95;
    margin-bottom: 16px;

    p {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        padding-top: 2px;
      }
    }

    .line {
      width: 1px;
      height: 12px;
      display: block;
      background: #dde1e6;
      margin: 0 6px;
    }
  }

  .elv-report-trade-drawer-info__total {
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #1e2024;
    position: relative;
  }

  ul {
    width: 100%;
    margin: 16px 0;
    border-top: 1px solid #edf0f3;
    display: flex;
    flex-wrap: wrap;

    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 50%;
      height: 54px;
      box-sizing: border-box;
      border-bottom: 1px solid #edf0f3;

      &:nth-child(2n) {
        padding-left: 8px;
        border-left: 1px solid #edf0f3;
      }

      &:nth-child(1),
      &:nth-child(2) {
        height: 60px;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: #838d95;
      }
    }

    .elv-report-trade-drawer-info__from {
      .elv-report-trade-drawer-info__from-title {
        display: flex;
        align-items: center;

        svg {
          margin-left: 6px;
          cursor: pointer;
          fill: #aaafb6;

          &:hover {
            fill: #1e2024;
          }
        }
      }

      .elv-report-trade-drawer-info__from-content {
        display: flex;
        align-items: center;
        margin-top: 2px;
        padding: 0px 12px 0px 6px;
        height: 23px;
        box-sizing: border-box;
        border-radius: 12px;
        border: 1px solid #edf0f3;
        background: #f9fafb;

        span {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #0e0f11;
          max-width: 98px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        img {
          width: 14px;
          height: 14px;
          display: block;
          margin-right: 4px;
        }

        &.normal {
          background: none;
          border: none;
          display: flex;
          justify-content: flex-start;
          padding: 0px;
        }

        .elv-base-cell-icon-title {
          padding: 0px;

          .elv-base-cell-icon-title-title {
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 500 !important;
          }
        }

        .elv-base-cell-vertical-text {
          .elv-base-cell-vertical-text-up {
            font-weight: 500 !important;
          }

          div {
            text-align: left;
          }
        }
      }
    }
  }

  .elv-report-trade-drawer-journal {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;

    .elv-report-trade-drawer-journal-title {
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }

    .elv-report-trade-drawer-journal-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .elv-report-trade-drawer-journal-item__type {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding: 10px 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #dde1e6;
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
      margin-bottom: 8px;

      p {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
      }

      span {
        color: #838d95;
        font-family: 'Barlow';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
      }
    }

    ol {
      background: #ffffff;
      border: 1px solid #ced7e0;
      border-radius: 4px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #0e0f11;

      li {
        min-height: 40px;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: stretch; /* 子元素的高度将与父元素相匹配 */
        justify-content: center;
        box-sizing: border-box;
        border-top: 1px solid #edf0f3;

        &:first-child {
          min-height: 28px;
          border-top: 0px;
        }

        &.is-matched {
          background-color: #f1f8ea;
        }
      }

      .elv-report-trade-drawer-journal-item-entry__direction {
        min-height: 40px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.title {
          min-height: 28px;
        }
      }

      .elv-report-trande-drawer-journal-item-entry__account {
        width: 156px;
        min-height: 40px;
        height: 100%;
        padding: 5px 10px;
        box-sizing: border-box;
        border-left: 1px solid #edf0f3;
        border-right: 1px solid #edf0f3;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 5px;

        &.title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #0e0f11;
          min-height: 28px;
        }

        &.has-match {
          width: 129px;
        }

        .elv-report-trade-drawer-journal-item-entry__auxiliary-list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          gap: 4px;
        }

        .elv-report-trade-drawer-journal-item-entry__account-auxiliary {
          box-sizing: border-box;
          height: 19px;
          padding: 0px 9px;
          border-radius: 16px;
          max-width: 136px;
          border: 1px solid #dde1e6;
          background: #f9fafb;
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .elv-report-trade-drawer-journal-item-entry__match {
        width: 28px;
        max-width: 28px;
        min-height: 40px;
        flex: 1;
        box-sizing: border-box;
        border-left: 1px solid #edf0f3;
        display: flex;
        align-items: center;
        justify-content: center;

        &.title {
          min-height: 28px;
        }

        svg {
          cursor: pointer;
        }
      }

      .elv-report-trade-drawer-journal-item-entry__debit {
        border-right: 1px solid #edf0f3;
      }

      .elv-report-trade-drawer-journal-item-entry__debit,
      .elv-report-trade-drawer-journal-item-entry__credit {
        width: 75px;
        min-height: 40px;
        padding-right: 10px;
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        overflow: hidden;
        word-break: break-word;
        text-align: right;

        span {
          color: #838d95;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          white-space: nowrap;
          overflow: hidden;
        }

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #0e0f11;
          min-height: 28px;
        }
      }
    }
  }
}
</style>
